/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(8, 1),
    },
  },
  description: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 3),
    },
  },
  step: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  stepAvatar: {
    height: 64,
    marginBottom: theme.spacing(1),
    width: 64,
  },
  stepAvatarBorder: {
    border: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'dashed',
    height: 196,
    width: 196,
  },
  stepContent: {
    alignItems: 'center',
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    maxWidth: 202,
    padding: theme.spacing(0, 1.5),
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      maxWidth: 312,
      padding: theme.spacing(0, 4),
    },
  },
  stepNum: {
    margin: theme.spacing(1),
  },
  stepTransition: {
    margin: theme.spacing(8, 0),
    transform: 'rotate(90deg)',
    zIndex: 4000,
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(8, 0),
      transform: 'rotate(0deg)',
    },
  },
}));
