import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';

import SEO from 'components/seo';
import ImageStep1 from 'assets/img/procedure-step-1.png';
import ImageStep2 from 'assets/img/procedure-step-2.png';
import ImageStep3 from 'assets/img/procedure-step-3.png';
import ImageStep4 from 'assets/img/procedure-step-4.jpg';
import ImageTrans from 'assets/img/procedure-transition.png';
import AppLayout from 'app/AppLayout/AppLayout';
import useStyles from './Procedure.styles';

const Procedure = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const texts = t('routes.procedure');

  const borders = [true, true, true, true];
  const nums = ['1', '2', '3', '4'];
  const stepImages = [ImageStep1, ImageStep2, ImageStep3, ImageStep4];
  const transitionImages = [ImageTrans, ImageTrans, ImageTrans, null];
  const steps = [...Array(4).keys()].map(index => ({
    border: borders[index],
    image: stepImages[index],
    num: nums[index],
    title: t(`routes.procedure.steps.${index + 1}`),
    transitionImage: transitionImages[index],
  }));

  return (
    <AppLayout
      headerTransparent={false}
      subheader={{
        title: texts.title,
      }}
    >
      <SEO
        description={t('routes.procedure.meta.description')}
        title={t('routes.procedure.meta.title')}
      />
      <Container className={classes.root} maxWidth="lg">
        <Typography className={classes.description} variant="body1">
          {texts.description}
        </Typography>
        <Container className={classes.content} maxWidth="lg">
          {steps.map(({ border, image, num, title, transitionImage }, index) => (
            <Box key={title} className={classes.step}>
              <Box className={classes.stepContent}>
                <Avatar
                  alt={`step-${index}`}
                  className={clsx(classes.stepAvatar, border && classes.stepAvatarBorder)}
                  src={image}
                />
                {title && (
                  <>
                    <Typography variant="h5">
                      {num}
                    </Typography>
                    <Typography className={classes.stepTitle} variant="body1">
                      {title}
                    </Typography>
                  </>
                )}
              </Box>
              {transitionImage && (
                <img
                  alt={`procedimiento ${index}`}
                  className={classes.stepTransition}
                  loading="lazy"
                  src={transitionImage}
                />
              )}
            </Box>
          ))}
        </Container>
      </Container>
    </AppLayout>
  );
};

export default Procedure;
